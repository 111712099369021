body {
  background-color: #f5f7fa;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
}


.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Adjusts vertical alignment */
  text-align: center;
  background-color: white; /* Sets the background color of the box to white */
  min-width: 240px; /* Sets the minimum width of the box to 240px */
  max-width: 1000px; /* Sets the maximum width of the box to 1000px */
  margin: 10vh auto 0 auto; /* Adds top margin and centers the box horizontally */
  padding: 20px; /* Adds some padding inside the box */
  box-shadow: 0 0 10px rgba(0,0,0,0.1); /* Adds a little shadow around the box */
  width: fit-content; /* Makes the box as wide as the content, respecting min/max-width */
  border-radius: 4px;
  padding: 40px;
}

.button {
  background-color: #0270ba;
  color: #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: 10px 20px;
  border: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown {
  width: 100%;
  max-width: 280px;
  padding: 10px;
  margin-bottom: 20px; /* Adds some margin between the input elements and the text */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}

.textInput {
  width: 100%;
  max-width: 280px;
  padding: 10px;
  margin-bottom: 20px; /* Adds some margin between the input elements and the text */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}

.button:disabled {
  background-color: #979797;
}

.text {
  color: #000000;
  font-size: 20px;
  margin-bottom: 20px;
}
